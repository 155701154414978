@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";
.errormessage {
  margin-top: 4px;
  margin-left: -1px;
  max-width: 100%;
  @include small-device {
    margin-bottom: 10px;
  }
  &__errorText {
    font-family: "Mark Pro";
    font-size: 13;
    color: red;
    text-align: "left";
  }
}
