@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";
.input-area {
  @include small-device {
    margin-left: 10%;
    margin-right: 10%;
  }
  .input {
    background: #ffffff;
    border: 2px solid #ebebed;
    position: relative;
    border-radius: 12px;
    display: flex;
    overflow: hidden;

    height: 60px;
    flex-direction: column;
    margin-bottom: 10px;
    &__close {
      position: absolute;
      left: 177px;
      top: 16px;
    }
    &__label {
      font-family: "Mark Pro", sans-serif;
      font-weight: 500;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: -0.3px;
      color: #888a97;
      padding-top: 9px;
      padding-left: 16px;
    }
    &__prefix {
      font-family: "Mark Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      outline: none;
      letter-spacing: -0.4px;
      color: #383c52;
      margin-top: 2px;
      margin-left: 16px;
      padding-right: -16px;
    }
    &__inputField {
      border: none;
      text-align: left;
      padding-left: 16px;
      margin-top: 2px;
      font-family: "Mark Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      outline: none;
      letter-spacing: -0.4px;
      color: #383c52;
      -webkit-appearance: none;
      border-bottom-left-radius: 10px;
    }
    &__toolTip {
      position: absolute;
      left: 102%;
      bottom: 19%;
      @include small-device {
        left: 102%;
        bottom: 17.5%;
      }
    }
    &__popover {
      position: absolute;
      left: 85.5%;
      bottom: 70%;
      @include small-device {
        left: 50%;
        bottom: 70%;
      }
      &__text {
        position: absolute;
        left: 87%;
        bottom: 90%;
        width: 190px;
        font-family: "Mark Pro";
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.3px;
        color: #ffffff;
        @include small-device {
          left: 52%;
          bottom: 90%;
        }
      }
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
