@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";
.dialog{
    display: flex;
    justify-content: center;
    align-items: center;
    &__title {
        font-family: 'Mark Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.6px;
        color: #383C52;
    }
    &__button {
        @include small-device {
            width: 100%;
         }
    }
    &__row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #F5F5F6;
        border-radius: 12px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-bottom: 2%;
        max-height: 72px;
        width: 100%;
        @include small-device {
            max-height:auto;
            width: auto;
        } 
        &__rowText{
            display: flex;
            flex-direction: row;
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 12px;
            padding-bottom: 12px;
            margin-bottom: 2%;
            align-items: center;
        }
        &__title {
            font-family: 'Mark Pro';
            font-weight: 400;
            font-size: 16px;
            color: #383C52;
        }
        &__content {
            font-family: 'Mark Pro';
            font-weight: 400;
            font-size: 14px;
            color: #606375;
        }
    }
}