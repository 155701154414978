@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";

.tab {
    display: flex;
    flex-direction: row;
    padding: 0;
    @include small-device {
        padding: 0;
    } 
    &__selected__section {
        background-color: #383C52;
        border-radius: 10px;
        margin-right: 26px;
        padding: 10px;
        height: 33px;
        color:white;
        margin-top: 48px;
        cursor:pointer;
        @include small-device {
            margin-right: 6px;
            padding-bottom: 15px;
        } 
    }
    &__unselected__section {
        border-radius: 10px;
        margin-right: 26px;
        padding: 10px;
        height: 33px;
        margin-top: 48px;
        color: #ACB6BD;
        cursor:pointer;
        @include small-device {
            margin-right: 6px;
        } 
    }
}