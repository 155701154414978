@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";

.header {
  height: 100px;
  background: #ffff;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 33px;
  z-index: 1000;
  width: 100%;
  @include small-device {
    padding-top: 2%;
    &__headerRegular {
      display: flex;
      width: 100%;
      flex-direction: row;
      position: absolute;

      right: 0;
      left: 0;
      padding-left: 20px;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  @media (min-width: 960px) and (max-width: 1160px) {
    padding-left: 30px;
  }

  &__bar {
    display: none;
    @include small-device {
      display: flex;
      position: absolute;
      right: 2%;
      cursor: pointer;
      margin-right: 15px;
      z-index: 1000;
    }
  }
  &__logo {
    width: 210px;
    cursor: pointer;
  }
  &__content {
    margin: 0 auto;
    margin-bottom: 100px;
    padding: 0;
    transition: 0.3s ease padding;
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1140px;
    & > div {
      flex: 1;
      white-space: nowrap;
      &:nth-child(2) {
        display: flex;
        justify-content: flex-end;
        height: 100%;
      }
      &:last-of-type {
        display: flex;
        justify-content: flex-end;
        flex: 0;
      }
    }
  }
  &__right_menu {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &__searchInput {
      margin-top: -6%;
      margin-right: 10%;
      @include small-device {
        display: block;
        margin-right: 5%;
      }
    }
    &__searchList {
      margin-left: 25%;
      border: 1px solid #ebebed;
      border-radius: 8px;
      width: 200px;
      height: auto;
      max-height: 180px;
      background-color: white;
      &__divider {
        border-bottom: 1px solid #ebebed;
        width: 100%;
      }
      &__label {
        font-family: "Mark Pro";
        font-weight: 500;
        font-size: 11px;
        line-height: 0;
        letter-spacing: -0.3px;
        color: #888a97;
        padding-top: 0px;
        padding-left: 16px;
        cursor: pointer;
      }
    }
    &__search {
      padding-top: 6px;
      cursor: pointer;
      @include small-device {
        margin-top: 38px;
        margin-right: 135px;
        cursor: pointer;
        position: fixed;
      }
      @media (max-width: 768px) {
        margin-top: 46px;
        margin-right: 95px;
        cursor: pointer;
        position: fixed;
      }
      &__animated {
        position: absolute;
        -webkit-transform: translate3d(-50%, -50%, 0);
        -moz-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);
        margin-top: "2%";
        margin-right: 45%;
        @include small-device {
          display: block;
          position: absolute;
          -webkit-transform: translate3d(-50%, -50%, 0);
          -moz-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
          margin-top: "2%";
          margin-right: 85%;
        }
        @media (max-width: 499px) {
          display: block;
          position: absolute;
          -webkit-transform: translate3d(-50%, -50%, 0);
          -moz-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
          margin-top: "2%";
          margin-right: 125%;
        }
      }
    }
  }

  &__toggler {
    @include small-device {
      display: none;
    }
    height: 36px;
    width: auto;
    border: 1px solid #ebebed;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    background-color: #ffff;
    font-family: "Mark Pro";
    font-weight: 700;
    font-size: 16px;
    color: #383c52;
    cursor: pointer;
    margin-left: 25px;
  }
  &__language {
    border: 0 solid #ebebed;
    background-color: #ffff;
    height: 36px;
    width: 36px;
    color: #383c52;
    margin-left: 15px;
    font-family: "Mark Pro";
    font-weight: 700;
    font-size: 16px;

    cursor: pointer;
    @include small-device {
      margin-top: 49px;
      color: #3ac7f3;
      margin-right: 45px;
    }
    @media (min-width: 960px) and (max-width: 1160px) {
      margin-right: 60px;
    }
  }
  &__welcomeDrawer {
    display: none;
    @include small-device {
      display: block;
      background-image: linear-gradient(320deg, #3ac7f3 10%, #1581be);
      padding-top: 90px;
      width: 100%;
      height: 100%;
      z-index: 2000;
    }
    &_bar {
      cursor: pointer;
    }
    &__smallHeightDevice {
      padding-top: 60px;
    }
    &__divider {
      @include small-device {
        display: none;
      }
    }
    hr {
      width: 100%;
      margin: 0 auto;
      background-color: #1581be;
      opacity: 0.15;
    }

    &__logo {
      display: none;
      background-color: #1581be;
      @include small-device {
        height: 62px;
        align-items: center;
        margin-top: -18%;
      }
    }
    &__close {
      cursor: pointer;
      position: absolute;
      left: 90%;
    }
  }
}

.logo {
  font-size: 26px;
  font-weight: 700;
}

.nav {
  width: 100%;
  position: fixed;
  top: 100px;
  right: -100%;
  background: rgba(#ffff, 0.8);
  text-align: center;
  padding: 100px 0;

  &--open {
    transition: 0.2s ease right;
    right: 0;
  }
  &__content {
    flex-direction: column;
    @include small-device {
      display: none;
    }
  }

  &__item {
    display: block;
    //   padding:100px;

    text-decoration: none;
    color: #383c52;

    height: 100%;

    transition: 0.3s ease color;
    &:hover {
      color: $secondary-color;
    }
  }

  // @include breakpoint(md) {
  //     position: static;
  //     display: grid;
  //     grid-template-columns: repeat(3, 1fr);
  //     background: initial;
  //     width: auto;
  //     padding: 0;

  //     &__item {
  //         padding: 0 70px;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //     }
  // }

  &__button__container {
    // @include breakpoint(md) {
    //     display: none;
    // }
    padding: 100px;
  }
}
.buttons {
  display: flex;
  flex-direction: row;
  align-content: center;
  &__home {
    margin-right: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
    cursor: pointer;
  }
  &__text {
    text-decoration: none;
    color: #383c52;
    font-size: $font-regular-big;
    font-family: "Mark Pro";
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.5px;
    padding: 25px;
  }
}
.button {
  font-weight: 500;
  color: $secondary-color;
  border: 2px solid $secondary-color;
  padding: 50px 70px;
  border-radius: 50px / 2;
  cursor: pointer;

  box-shadow: 0 0 0.5em 0 $secondary-color;

  &:active {
    transform: scale(0.98);
    border-radius: 0;
  }
  &:hover {
    box-shadow: 0 0 0.75em 0 $secondary-color;
  }
}
.welcomeDrawer {
  display: none;

  @include small-device {
    display: block;
  }
  &-bar {
    cursor: pointer;
  }
}

.welcomeDrawer-drawer {
  min-width: 100%;
  background-image: linear-gradient(320deg, #3ac7f3 10%, #1581be);
  padding-top: 90px;

  &--smallHeightDevice {
    padding-top: 60px;
  }
  &--divider {
    @include small-device {
      display: none;
    }
  }

  @include small-device {
    width: 100%;
    padding-top: 0;
  }

  hr {
    width: 100%;
    margin: 0 auto;
    background-color: #1581be;
    opacity: 0.15;
  }

  .MuiLink-root {
    color: #1581be;
    font-size: 18px;
  }
  &--logo {
    display: none;
    background-color: #1581be;
    @include small-device {
      display: flex;
    }
  }
}
.locationIcon {
  color: #fff;
}
@media (min-width: 768px) {
  .header {
    &__content {
      justify-content: space-between;
    }
  }
}
