@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";
.app__card {
    display: flex;
    flex-direction: row;
    background-color: #F5F5F6;
    border-radius: 12px;
    width:350px;
    height:72px;
    align-items:center;
    padding-left: 16px;
    padding-right: 16px;
    @include small-device {
        height:96px;
    }
    &__content {
        flex-direction: column;
        margin-left: 16px;
    }
    &__icon__right {
        width: 24px;
        height: 24px;
    }
    &__title {
        font-family: 'Mark Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.4px;
        color: #383C52;
    }
    &__subtitle {
        font-family: 'Mark Pro';
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.3px;        
        color: #606375;
    }
}
    