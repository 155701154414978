@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";
.home {
  margin-top: 10px;
  padding-bottom: 40px;
  &__bgColor {
    display: flex;
    background-color: #eefaff;
    height: 100%;
    @include small-device {
      padding-top: 0px;
    }
  }
  &__swiper__bg {
    display: flex;
    width: 100%;
    max-width: 1140px;
    margin: 0px auto;
    box-sizing: border-box;
    justify-content: space-between;
    max-height: fit-content;
    @include small-device {
      height: 100%;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      max-height: fit-content;
    }
    &__dnm {
      @include small-device {
        justify-content: center !important;
        flex-direction: column;
        align-items: center;
      }
    }
    @media (min-width: 960px) and (max-width: 1160px) {
      padding-left: 30px;
      padding-right: 30px;
    }
    &__cards {
      display: flex;
      width: 40%;
      justify-content: flex-end;
      @include small-device {
        display: none;
      }
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        max-height: 526px;
      }
    }
    &__cards2 {
      display: flex;
      width: 100%;
      justify-content: center;
      @include small-device {
        display: none;
      }
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
    &__cardsTiny {
      display: none;
      @include small-device {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        img {
          object-fit: contain;
          max-height: 526px;
          @include small-device {
            max-height: 270px;
          }
        }
      }
    }
    &__home__background {
      position: absolute;
      right: 0px;
      @include small-device {
        display: none;
      }
    }
    &__home__background2 {
      display: flex;
      width: 100%;
      height: 100%;
      @include small-device {
        display: none;
      }
    }
    &__home__background2Tiny {
      display: none;
      @include small-device {
        display: flex;
        width: 100%;
        height: 100%;
      }
    }
    &__leftWidth {
      width: 50%;
    }
    &__left {
      background-color: #eefaff;
      @include small-device {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        background-color: #eefaff;
        width: auto;
        padding-top: 0px;
      }
    }
    &__title {
      font-weight: 700;
      font-size: 60px;
      line-height: 76px;
      letter-spacing: -2px;
      color: #383c52;
      margin-top: 89;
      @include small-device {
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        letter-spacing: -0.5px;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 40px;
        margin-bottom: 30px;
      }
    }
    &__content {
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.4px;
      color: #606375;
      mix-blend-mode: normal;
      @include small-device {
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.3px;
        mix-blend-mode: normal;
        width: 100%;
        margin-top: -15px;
        padding-left: 40px;
        padding-right: 40px;
        box-sizing: border-box;
      }
    }
    &__button {
      width: 328px;
      @include small-device {
        display: none;
      }
    }
  }
  &__slider {
    display: flex;
    flex-direction: row;
    &__card {
      margin: 12px;
      cursor: pointer;
    }
  }
  &__circle {
    display: flex;
    width: 48px;
    height: 48px;
    background-color: #eefaff;
    border-radius: 100%;
    margin-top: 50%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include small-device {
      display: none;
    }
  }
  &__announcement {
    font-family: "Mark Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 55px;
    text-align: center;
    letter-spacing: -1.2px;
    color: #383c52;
    margin-top: -40px;
    @include small-device {
      font-size: 24px;
    }
  }
  &__announcementCardContainer {
    margin-left: 59px;
    margin-right: 59px;
    @include small-device {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &__notification__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 55px;
    margin-right: 55px;
    margin-top: 64px;
    @include small-device {
      display: none;
    }
    &__swiper {
      display: flex;
      @include small-device {
        display: none;
      }
    }
  }

  &__notification__containerTiny {
    display: none;
    @include small-device {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  &__buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 48px;
  }
}
.top-slider {
  .swiper-slide {
    height: auto !important;
  }
}
.img-mobile-margin {
  @media screen and (max-width: 500px) {
    padding-top: 30%;
  }
}

footer {
  padding-top: 0 !important;
}
