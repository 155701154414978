.tab__box {
  width: 273px;
  height: 88px;
  background-color: #f7f8f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  box-sizing: border-box;
  position: relative;
  margin: 8px;
  cursor: pointer;
  text-align: center;
  @media screen and (max-width: 620px) {
    justify-content: center;
    height: auto;
    padding: 12px 8px;
    margin-bottom: 32px;
    width: auto;
    min-height: inherit;
    flex: auto;
    margin: 0;
  }
  &__text {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.5px;
    color: #004469;
    margin-top: 26px !important;
    @media screen and (max-width: 620px) {
      all: unset;
      font-weight: 700;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: -0.5px;
      color: #004469;
      margin: 0px !important;
    }
  }
}
