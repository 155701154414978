@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";

.footer {
  background-color: white;
  display: flex;
  bottom: 0;
  font-size: 25px;
  height: auto;
  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #606375;
    margin: 0;
    margin-top: 13px;
    margin-bottom: 5px;
    padding: 0;
  }
  @include small-device {
    display: flex;
    height: auto;
    flex-direction: column-reverse;
  }
  &__line {
    border-top: 2.8px solid #ebebed;
    margin-left: -100px !important;
    margin-right: -100px !important;
  }
  &__text {
    font-family: "Mark Pro";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #afb1ba;
    padding-left: 0;
    @media (min-width: 450px) and (max-width: 900px) {
      text-align: center;
    }
  }
  &__left {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    padding-right: 30px;
    margin-top: -100px;
    @include small-device {
      padding-right: 0px;
      width: 100%;
      margin-top: 10%;
      box-sizing: border-box;
      padding-left: 0px;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .web-ibb-logo {
      @include small-device {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
    .tcmb__img {
      margin-left: -10px;
    }
  }
  &__detail {
    padding: 0px;
    padding-left: 0px;
    &__l__mobile {
      img {
        display: none;
        @include small-device {
          display: block !important;
        }
      }
      @include small-device {
        display: flex;
        align-items: flex-end;
        p {
          padding: 0;
          margin: 0;
          box-sizing: border-box;
        }
      }
      @media (min-width: 450px) and (max-width: 900px) {
        width: 100%;
        justify-content: center;
      }
    }
  }
  &__row1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 450px;
    margin: 0px auto 6px auto;
    justify-content: space-between;
    svg {
      @include small-device {
        max-width: 50%;
      }
    }
    & > .link {
      cursor: pointer;
    }
  }
  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 450px;
    margin: 0px auto;
    & > div {
      flex: 1;
    }
    svg {
      @include small-device {
        max-width: 50%;
      }
    }
  }
  &__right {
    width: 70%;
    display: flex;
    align-items: flex-end;
    height: auto;
    margin-top: -70px;
    padding-right: -30px !important;
    flex-direction: column;
    @include small-device {
      display: flex;
      height: auto;
      align-items: flex-start;
      flex-direction: column;
    }
    &__topHeight {
      margin-top: 75px;
      @include small-device {
        margin-top: 0;
      }
    }
    &__menuRow {
      display: flex;
      flex-direction: row;
      @include small-device {
        display: flex;
        flex-direction: column;
      }
    }
    &__container {
      display: flex;
      flex-direction: row;
    }
    &__link {
      font-family: "Mark Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #606375;
      text-decoration: none;
      padding-top: 12px;
      padding-bottom: 10px;
      margin-right: -20px;
      width: 180px;
      &__bireysel {
        display: flex;
        flex-direction: column;

        @include small-device {
          display: none;
        }
      }
      &__bireyselSmall {
        display: none;
        flex-direction: column;
        @include small-device {
          display: flex;
          flex-direction: column;
        }
      }
    }
    &__menu {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 50px;
      @include small-device {
        margin-left: 10px;
      }
      &__rightMargin {
        margin-right: -33px;

        @media (min-width: 1000px) and (max-width: 1160px) {
          margin-right: -40px;
        }
      }
    }

    &__height {
      height: 10px;
    }
    &__clickable {
      cursor: pointer;
    }
    &__text2 {
      font-family: "Mark Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #1581be;
      text-decoration: none;
      letter-spacing: -0.5px;
      padding: 8px 8px 8px 0;
    }
  }
}

@media (min-width: 768px) {
  .footer {
    &__right {
      &__container {
        justify-content: flex-start;
      }
    }
  }
}

.version__number {
  font-size: 12px;
  color: #b2b4bd;
  padding: 0px;
  margin: 0;
  width: 100%;
}

.footer.pageContainer {
  @include small-device {
    padding-top: 90px;
  }
}
