@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";

.tooltip {
  position: relative;
  &__box {
    z-index: 99999;
    background-color: #606375;
    border-radius: 10px;
    width: 250px;
    height: 90px;
  }
}
