.app__button {
    width: 100%;
    letter-spacing: -0.4px;
    text-align: center;
    &__leftIcon {
      display: flex;
      justify-content: flex-start;
      position: absolute;
      left:16px;
    }
  }
    