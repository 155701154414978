@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";
.maskInput {
  background: #ffffff;
  border: 2px solid #ebebed;
  position: relative;
  border-radius: 12px;
  display: flex;
  overflow: hidden;
  @include small-device {
    margin-left: 10%;
    margin-right: 10%;
  }
  height: 60px;
  flex-direction: column;
  margin-bottom: 10px;
  &__close {
    position: absolute;
    left: 170px;
    top: 15px;
  }
  &__label {
    font-family: "Mark Pro";
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: -0.3px;
    color: #888a97;
    padding-top: 9px;
    padding-left: 16px;
  }
  &__prefix {
    font-family: "Mark Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    letter-spacing: -0.4px;
    color: #383c52;
    margin-top: 2px;
    margin-left: 16px;
    padding-right: -16px;
  }
  &__inputField {
    border: none;
    text-align: left;
    padding-left: 16px;
    margin-top: 2px;
    font-family: "Mark Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    letter-spacing: -0.4px;
    color: #383c52;
    -webkit-appearance: none;
  }
}
maskInput::-webkit-outer-spin-button,
maskInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Works for Firefox */
maskInput[type="number"] {
  -moz-appearance: textfield;
}

.personalization__relative {
  position: relative;
}

.helper-tooltip {
  position: absolute;
  right: -32px;
  top: 14px;
  @include small-device {
    right: 0;
  }
  .helper-tooltip-content {
    position: absolute;
    display: none;
    transform: translateX(-50%);
    bottom: calc(100% + 10px);
    left: 50%;
    width: 250px;
    border-radius: 8px;
    color: #fff;
    padding: 16px 14px;
    font-size: 14px;
    &::before {
      content: "";
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      top: 100%;
      width: 0px;
      height: 0px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #606375;
    }
  }
  &:hover {
    .helper-tooltip-content {
      display: block;
      background-color: #606375;
    }
  }
}
