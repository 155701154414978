$secondary-color: #004469;
$light-background-color: #f8f8fb;
$disable-color: #cad3da;
$primary-border-color: #ebeef0;
$blue-color: #1aaadc;
$light-blue: #5db7de;
$dark-blue: #003d84;
$soft-blue: #eefaff;
$moderate-cyan: #0072bc;
$dark-gray-color: #383c52;
$gray-color: #acb6bd;
$light-gray-color: #ebeef0;
$white-gray-color: #dee4e6;
$icewind-gray: #e7ebed;
$headline-dark: #b9c2ca;
$toast-error: #ffeeee;
$dark-primary-color: #1aaadc;

$input-color: $secondary-color;
$checkbox-label-color: #606375;
$separator-color: #f5f5f6;
$dark-separator-color: #adc2ca;
$disabled-color: #d7d8dc;
$placeholder-color: #c3c4cb;

$error-border-color: #ffabab;
$error-bg-color: rgba(255, 171, 171, 0.07);
$error-text-color: #f37268;

$tiny-device-width: 639px;
$small-device-width: 959px;
$regular-device-width: 1000px;
$design-device-width: 1139px;
$big-device-width: 1599px;
$large-device-width: 2100px;

$tiny-device-height: 500px;

$small-gutter: 10px;
$regular-gutter: 20px;
$big-gutter: 30px;
$bigger-gutter: 40px;
$large-gutter: 50px;

$font-tiny: 9px;
$font-smaller: 12px;
$font-small: 14px;
$font-regular: 16px;
$font-regular-big: 18px;
$font-medium-big: 20px;
$font-big: 24px;
$font-bigger: 32px;
$font-largest: 48px;

$radius-regular: 8px;
$radius-large: 12px;
$primary-color: #55d3ff;
$md: 48em;

$toast-success: #d4edda; // Açık yeşil arka plan rengi
$toast-success-text: #3ac562; // Yeşil metin rengi
$toast-danger: #f8d7da; // Açık kırmızı arka plan rengi
$toast-danger-text: #721c24; // Koyu kırmızı metin rengi
$toast-warning: #fff3cd; // Açık sarı arka plan rengi
$toast-warning-text: #856404; // Koyu sarı metin rengi
$toast-info: #d1ecf1; // Açık mavi arka plan rengi
$toast-info-text: #0c5460; // Koyu mavi metin rengi
