@import "index.scss";

.news__box {
  text-decoration: none;
  cursor: pointer;
  &__image {
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    > img {
      width: 100%;
      max-height: 207px;
      vertical-align: middle;
      box-sizing: border-box;
      overflow: hidden;
      object-fit: cover;
    }
  }
  &__content {
    background: #ffffff;
    border: 1px solid #f5f5f6;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 20px;
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      letter-spacing: -0.3px;
      color: #888a97;
    }
    h3 {
      font-size: 18px;
      line-height: 23px;
      display: flex;
      align-items: center;
      letter-spacing: -0.5px;
      color: #383c52;
      margin-top: 16px;
      margin-bottom: 12px;
    }
    p {
      font-weight: 450;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.4px;
      color: #383c52;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
