body {
  margin: 0;
  font-family: "Mark Pro";
  overflow-x: hidden;

  *:focus,
  *:active {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -moz-user-select: text;
    user-select: auto;
  }

  .pageContainer {
    width: 100%;
    max-width: 1140px;
    margin: 0px auto;
    box-sizing: border-box;
    padding-top: 128px;
    margin-bottom: 50px;
    @include small-device {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    @include design-device {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  ul {
    margin-left: 0;
    padding-left: 0;
  }

  input[type="password"] {
    font-family: Verdana, sans-serif !important;
    letter-spacing: 1px;
  }
  input[type="text"],
  input,
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  // MATERIAL UI

  .MuiCheckbox-colorPrimary.Mui-checked:hover,
  .MuiFilledInput-root:hover {
    background-color: transparent;
  }

  .MuiInputBase-input:focus {
    border-color: $soft-blue;
  }

  .MuiTypography-h5 {
    @include small-device {
      font-size: $font-regular-big;
    }
  }

  .MuiTypography-h6 {
    @include small-device {
      font-size: $font-small;
    }
  }
  .MuiDialog-root {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MuiDialog-paper {
    @include small-device {
      margin: 45px 10px;
      width: 100%;
    }
  }

  .MuiCheckbox-root.Mui-focusVisible {
    color: $soft-blue;
  }

  .MuiButton-root.Mui-focusVisible {
    border-color: $soft-blue;
  }

  .MuiFormLabel-root.Mui-error {
    color: $error-border-color;

    + div input {
      background-color: $error-bg-color;
      border-color: $error-border-color;
      color: $error-text-color;
    }
    + div textarea {
      background-color: $error-bg-color;
      border-color: $error-border-color;
      color: $error-text-color;
    }
  }
  .MuiFilledInput-root.Mui-disabled {
    background-color: transparent;
  }

  // GENERAL CLASSSES

  .flexAlignCenter {
    display: flex;
    align-items: center;
  }
  .cursor-pointer {
    cursor: pointer !important;
  }
}
