@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translate(-50%, -60%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }
}
.announcement-popup {
  cursor: pointer;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  z-index: 1000;
  overflow: auto;
  max-width: 50vw;
  width: 100%;
  height: auto;
  
  img{
    width: 100%;
  }

  @media screen and (max-width: 860px) {
      max-width: 90vw;
  }

  &--closing {
    animation: fadeOut 500ms;
  }
}
.announcement-popup.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.5s;
}
.announcement-popup__header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.announcement-popup__description {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  text-align: justify;
  line-height: 1.6;
  padding: 0 1rem;
}


.announcement-popup__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    padding-bottom: 30%;
  }
}


.announcement-popup__link {
  font-size: 1.2rem;
  text-decoration: underline;
  color: black;
  margin-bottom: 1rem;
}

.announcement-popup__close {
  cursor: pointer;
  font-size: 1.2rem;
  background-color: transparent;
  border: 2px solid black;
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  outline: none;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
}
