@import "index.scss";

.press__image {
  width: 273px;
  min-height: 165px;
  background-color: #e7ebed;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;
  box-sizing: border-box;
  @include small-device {
    width: 100%;
    min-height: 120px;
    padding: 12px 20px;
  }
  &__image {
    height: 100%;
    max-height: 62px;
    @include small-device {
      width: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
      max-height: 32px;
      > svg {
        width: 100%;
      }
    }
  }
  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.3px;
    color: $dark-gray-color;
    @include small-device {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      padding: 0px;
      margin: 0px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &__link {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    &__text {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.3px;
      color: $dark-gray-color;
      padding: 0;
      margin: 0;
      @include small-device {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        margin-right: 5px;
      }
    }
  }
}
