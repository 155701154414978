@import "index.scss";

.announcementCard {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $small-gutter;
    padding: 15px 10px 17px 16px;
    height: 72px;
    box-sizing: border-box;
    background-color: $soft-blue;
    border-radius: $radius-large;
    background-color: $separator-color;
    width: 482px;
    cursor: pointer;
    @include small-device {
      background-color: $separator-color;
      width:auto;
      margin-left: 8px !important;
    }

    &__text {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 8px 16px;
      > * {
        padding: 0;
        margin: 0;
      }
    }
    &__title {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.4px;
      color: $dark-gray-color;
    }
    &__date {
      font-weight: 450;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.3px;
      color: #606375;
    }
  }
}
