@import "index.scss";

.press__colorbox {
  width: 273px;
  height: 165px;
  background-color: #e7ebed;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 42px;
  box-sizing: border-box;
  position: relative;
  @media screen and (max-width: 620px) {
    justify-content: center;
    height: 100px;
    padding: 12px 20px;
    margin-bottom: 32px;
  }
  p {
    padding: 0;
    margin: 0;
  }
  &__codes {
  }
  &__code {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #ffffff;
    @media screen and (max-width: 620px) {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.3px;
    color: #ffffff;
    margin-top: 26px !important;
    @media screen and (max-width: 620px) {
      position: absolute;
      top:80px;
      color: #383C52;
      width: 100%;  
      text-align: left;
    }
  }
}
