
@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";
.searchField {
    @include small-device {
        min-height: unset;
        padding-right: $big-gutter;
        padding-left: $big-gutter;
    }

    @include small-device {
        padding-right: $regular-gutter;
        padding-left: $regular-gutter;
    }
    display: flex;
    position: relative;
    width:100%;
    margin:50px;
    border-radius: 12px;
    background-color: white;
}
.MuiTextField-root {
    overflow: unset !important;
}
